@font-face {
  font-family: cdcIconFont;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/cdciconfont.woff2);
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292935;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Forces simple scrollbars within the application */
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(128, 135, 139, 0.8);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(128, 135, 139, 0.8);
}

body ::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}
